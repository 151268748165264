import React, { useState } from 'react';
import AllPrices from './components/AllPrices';
import SearchPage from './components/SearchComponent';
import { useUser } from './constants/UserContext';
import Card from './components/Card';

function App() {
  const { setPlz, setType, plz, type } = useUser();
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  //<PriceChart/>

  return (
    <div className="App">
      <SearchPage />
      <br />
      <div className="container text-center">
        <br />
        <div className="row align-items-start">
          <div className='col'>
          </div>
          <div className='col'>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col'>
            <Card className="scrollable container text-center" Chart={<></>} detail={<AllPrices />} />
          </div>
        </div>
        <br />
        <div className='row align-items-start container text-center'>
        </div>
        <br />
      </div>

    </div>
  );
}

export default App;
