import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import App from './App';
import { UserProvider } from './constants/UserContext';
import Docs from './Docs';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>} />
        <Route path="/docs" element={ <Docs/> } />
      </Routes>
  </BrowserRouter>
</UserProvider>
);