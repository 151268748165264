import React from 'react';
import { useNavigate } from 'react-router-dom';

function DocsNav() {
    const navigate = useNavigate();

  return (
    <nav className="navbar navbar-dark bg-dark">
    <div className="form-inline">
    <button className="btn btn-outline-danger my-2 my-sm-0" onClick={e => navigate("/")}>Zurrück</button>
    <div>
    <a className="btn btn-outline-success my-2 my-sm-0" href="https://status.buchner-leon.de/status/petrol" target="_blank" rel="noopener noreferrer">
      Status
    </a>
    </div>
    </div>
    </nav>
  );
}

export default DocsNav;
