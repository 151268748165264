import { createContext, useState, useContext, useEffect } from 'react';
import { isValid, stringLength } from './constants';
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [plz, setPlz] = useState("");
  const [type, setType] = useState("super");

  useEffect(() => {
    if (!isValid(type) && !stringLength(plz, 5) && plz === "" && type === "") {
        console.log("plz:",plz);
        console.log("type: ",type);
      setPlz("");
      setType("");
    }
  }, [plz, type]);

  return (
    <UserContext.Provider value={{ plz, type, setType, setPlz}}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
