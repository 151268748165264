import React from 'react';
import "../css/Card.css";

function Card(props){

    return(<>
        <div key={props.key ? props.key : ""} className={props.className ? props.className : "card"} >
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                        {props.Chart}
                    <div className="card-text">{props.detail}</div>
                </div>
        </div>
    </>);
}

export default Card;