import React from 'react';
import './iframe.css';
import DocsNav from './components/DocNav';

const REST_API_URL = process.env.REST_API_URL;

function Docs() {
  return (
    <div className="App">
      <DocsNav/>
      <div className="text-center docs" width="100%" height="100%">
        <a href='/backend'>Backend Link</a>
            <iframe src={REST_API_URL} title="Backend Doku" height="900" width="100%" allowFullScreen></iframe>
            <br/>
        </div>
        
    </div>
  );
}

export default Docs;
