import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Table.css';
import { useUser } from '../constants/UserContext';
import { NormalDate } from '../constants/constants';

const REST_API_URL = process.env.REACT_APP_REST_API_URL;

function AllPrices() {
    const { plz, type } = useUser();
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [average, setAverage] = useState(0.0);
    const [minPrice, setMinPrice] = useState(0.0);
    const [maxPrice, setMaxPrice] = useState(0.0);
    const [median, setMedian] = useState(0.0);
    const [noPrices, setNoPrices] = useState(true);

    useEffect(() => {

        function calculateMedian(arr) {
            // Step 1: Sort the array

            if (!Array.isArray(arr)) {
                throw new Error('Input is not an array. ->'+ typeof(arr));
            }
            
            const sortedArray = [...arr];

            sortedArray.sort((a, b) => a - b);
          
            // Step 2: Check if the array has an odd or even number of elements
            const middle = Math.floor(sortedArray.length / 2);

            //console.log("Sorted:",sortedArray);
            //console.log("Middle:",middle);
          
            if (sortedArray.length % 2 === 1) {
              // Odd number of elements
              return sortedArray[middle];
            } else {
              // Even number of elements
              const middle1 = sortedArray[middle - 1];
              const middle2 = sortedArray[middle];
              return (middle1 + middle2) / 2;
            }
          }

            if((plz === "" || type === "" || !plz || !type || plz !== null || type !== null)){
                setError(true);
            }
            if(!error){
                console.log(`${REST_API_URL}/petrol?plz=${plz}&type=${type}`);
                axios.get(`${REST_API_URL}/petrol?plz=${plz}&type=${type}`)
                    .then((response) => {
                        if (response.status === 200) {
                            setData(response.data);
                            if(response.data.length > 0){
                                // Calculate average price
                                const prices = response.data.map((entry) => entry.current_price);
                                //console.log(prices);
                                const median = Math.round(calculateMedian(prices) * 100) / 100;
                                //console.log("Median:", median);
                                setMedian(median);
                                const totalPrices = prices.reduce((total, price) => total + price, 0);
                                const calculatedAverage = totalPrices / prices.length;
                                setAverage(calculatedAverage);
                                const calculatedMinPrice = Math.min(...prices);
                                const calculatedMaxPrice = Math.max(...prices);
                                setMinPrice(calculatedMinPrice);
                                setMaxPrice(calculatedMaxPrice);
                                setNoPrices(false);
                            } else {
                                setNoPrices(true);
                            }
                        }
                    })
                    .catch((error) => {
                        setError(true);
                        setNoPrices(true);
                        console.log(error);
                    })
                    .finally(() => {
                        setError(false);
                    });
            }
    }, [plz, type]);

    function Prices(props) {
        return (
            <tr className="interactive-row">
                <td className={table_color(props)}>{props.data.current_price}</td>
                <td className={table_color(props)}>{props.data.petrol_station_name}</td>
                <td className={table_color(props)}>{props.data.petrol_station_address}</td>
                <td className={table_color(props)}>{props.data.petrol_station_closes}</td>
                <td className={table_color(props)}>{NormalDate(props.data.scraped_time)}</td>
            </tr>
        );
    }

    function table_color(props){
        if(props.data.current_price <= minPrice){
            return 'best';
        }
        if(props.data.current_price >= maxPrice){
            return 'badest';
        }
        return props.data.current_price <= average ? 'table-success' : 'table-danger'
    }

    function ListItems(props) {
        if (!error) {
            return (
                <div className="table-responsive-xll">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Price</th>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Closes</th>
                                <th scope="col">Last Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((val, index) => (
                                <Prices key={index} data={val} />
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return <div className="spinner-border"></div>;
        }
    }

    function Results(){
        if(error){
            return(<div className='resultsContainer'>
                <p>Your Results can't be read</p>
            </div>);
        } if(noPrices) {
            return(<div className='resultsContainer'>
            <p>Currently no prices</p>
            <br />
            </div>);
        }else{
            return(<div className='resultsContainer'>
            <p>Average Price: {average.toFixed(2)} Minimum Price: {minPrice.toFixed(2)} Maximum Price: {maxPrice.toFixed(2)} Median: {median}</p>
            <ListItems className="scrollable" data={data} />
            <br />
            </div>);
        }
    }

    return (
        <div className='priceContainer'>
            <Results/>
        </div>
    );
}

export default AllPrices;
