import { DateTime } from "luxon";

const validTypes = ['diesel', 'super', 'superE10'];

function isNumeric(str) {
  return /^\d+$/.test(str);
}

function stringLength(inputString, maxLength) {
  if(inputString === null || inputString === undefined) return false;
  if (inputString.length === maxLength && isNumeric(inputString)) {
    return true;
  } else {
    return false;
  }
}

function isValid(typeString){
  if(typeString === undefined) return false;
  if(!validTypes.includes(typeString)) return false;
  return true;
}

function NormalDate(date){
  const dateTime = DateTime.fromISO(date);
  const utcDateTime = dateTime.toUTC();
  return utcDateTime.toFormat("yyyy-MM-dd HH:mm");
}

function formatUTCDateWithWeekday(date) {
  const dateTime = DateTime.fromISO(date);
  const formattedDate = dateTime.setLocale('de').toLocaleString({
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long'
  });

  return formattedDate;
}

export {validTypes , isNumeric, stringLength, isValid, NormalDate, formatUTCDateWithWeekday };