import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { validTypes } from '../constants/constants';
import { useUser } from '../constants/UserContext';

function SearchPage() {
    const navigate = useNavigate();

    const { setType, setPlz } = useUser();

    //const location = useLocation();
    //const queryParams = new URLSearchParams(location.search);

    const [plz_text, setPLZ] = useState("78073"); //queryParams.get('plz')
    const [type_text, setTypeText] = useState("super"); //queryParams.get('type') || 'super'

    const handleSearch = () => {
      //const searchParams = new URLSearchParams();
      //searchParams.set('plz', plz_text);
      //searchParams.set('type', type_text);
      //const newQueryString = searchParams.toString();
      //window.history.replaceState({}, '', `?${newQueryString}`);
      setPlz(plz_text);
      setType(type_text);
    };

    useEffect(() => {
        //const searchParams = new URLSearchParams();
        //console.log("plz: "+searchParams.get('plz')+" type: "+searchParams.get('type'))
        //setPlz(searchParams.get('plz'));
        //setPLZ(searchParams.get('plz'));
        //setType(searchParams.get('type'));
    });

    useLayoutEffect(() => {
      //const searchParams = new URLSearchParams();
      //console.log("plz: "+searchParams.get('plz')+" type: "+searchParams.get('type'))
    });

  return (
    <nav className="navbar navbar-dark bg-dark">
    <div className="form-inline">
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon1">PLZ</span>
      </div>
      <input
        id="plz"
        type="text"
        maxLength="5"
        value={plz_text === undefined || plz_text === null ? "" : plz_text}
        className="form-control"
        placeholder="PLZ"
        aria-label="Username"
        aria-describedby="basic-addon1"
        onChange={(e) => setPLZ(e.target.value)}
      />
    </div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text" id="basic-addon1">Type</span>
      </div>
      <select id="fuelType"
       className="form-select form-control mr-sm-2" 
       aria-label="Default select example" 
       value={type_text === undefined || type_text === null ? "super" : type_text}
       onChange={(e) => setTypeText(e.target.value)}>
        {validTypes.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
    <button className="btn btn-outline-success my-2 my-sm-0" onClick={handleSearch}>Search</button>
    <div>
      <Link className="btn btn-outline-danger my-2 my-sm-0" to="/docs">Backend Doku</Link>
    <a className="btn btn-outline-success my-2 my-sm-0" href="https://status.buchner-leon.de/status/petrol" target="_blank" rel="noopener noreferrer">
      Status
    </a>
    </div>
    </div>
    </nav>
  );
}

export default SearchPage;
